export const hcpIIR: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  hcpCarousel: "",
  hcpBanner: "Banner - HTML - Emerging Science - HCP - US Global",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  hcpExitPopup: "Retina Canada Exit Popup New - Global",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Global",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  hcpSiteLogo: "Site logos - Global",
  hcpFootRef: "Clinical Trials HCP - References - Global",
  hcpNavCalloutPara: "Emerging science Footer Callout HTML - Emerging Science - HCP US Global",
  hcpTestOrRetestPara: "Test or Retest Patient Section HTML - Emerging Science - HCP US Global",
  hcpSciAdvHTML: "Science is advancing HTML - Emerging Science - HCP US Global",
  hcpEvolutionGTPara: "Evolution of genetic testing HTML - Emerging Science - HCP US Global",
  hcpLatUnderTrialHTML: "Latest Underway Trial HTML - Emerging Science - HCP US Global",
  hcpBreakthroughData: "Clinical Trial breakthrough science - Global",
  hcpDnaData: "Clinical Trials - DNA with Historical Data HTML - HCP US Global",
  hcpOverThirtyHeader: "Emerging science over 30 clinical trials - Global",
  backToTopGTM: "Back to top - emerging science",
  footerClassName: "investigating-irds-footer",
}