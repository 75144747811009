import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';
import {font,color} from "../../../css/common/variables"

export const NoCostGeneticTestingWrapper = styled.div`
.CTA-container {
	margin: 48px 0;

	@media ${device.ipadLandscapemin} {
		margin: 100px 0 0px;
	}

	.header {
		font-size: 25px;
		line-height: 1.2;
		font-family: ${font.gotham_bold};

		@media ${device.ipadLandscapemin} {
			font-size: 42px;
		}
	}
	p.header-bottom {
		padding: 0 20px;
		@media ${device.ipadLandscapemin} {
			padding: 0;
		}
		span.start-with-ird {
			color: #8000be;
			font-size: 20px;
			line-height: 24px;
			font-family: ${font.gotham_bold};
			@media ${device.ipadLandscapemin} {
				font-size: 27px;
				line-height: 38px;
			}
		}
	}
	.disclaimer-text {
		font-size: 14px;
		line-height: 18px;
		font-family: ${font.gotham_book};
		text-align: center;
		@media ${device.ipadLandscapemin} {
			font-size: 18px;
			line-height: 22px;
		}
		@media ${device.desktopsignup} {
			padding: 0 14rem;
		}
	}
	.genetic-para {
		font-size: 1.8rem;
		line-height: 1.22;
		font-weight: 500;
		font-family: ${font.gotham};

		@media ${device.ipadLandscapemin} {
			font-size: 2.2rem;
			line-height: 1.45;
		}
		@media ${device.desktopsignup} {
			padding: 0 13rem;
		}
	}
	.link-wrapper {
		.link-text {
			font-size: 1.8rem;
			line-height: 2.2rem;
			font-weight: 500;
			padding: 16px 24px;
			box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
			border: 1px solid rgb(255, 255, 255);
			background-color: rgb(128, 0, 190);
			color: #fff;
			border-radius: 6px;
			font-family: ${font.gotham};

			@media ${device.ipadLandscapemin} {
				font-size: 2.2rem;
    		line-height: 1.1;
				padding: 20px 46px 20px 22px;
			}

			&:hover {
				font-family: ${font.gotham_bold};
				background-color: #fff;
				color: rgb(128, 0, 190);
			}
		}
	}
}
`;
export const CalloutWithHeaderContainer = styled.div`
	padding-top: 88px;

	@media ${device.laptopMedium} {
		padding-top: 30px;
	}
	span {
		h2.over-heading-style {
			font-family: "Gotham", Arial, Helvetica, sans-serif;
			width: 90%;
			padding: 0;
			text-align: center;
			font-size: 2.5rem;
			line-height: 3rem;
			margin: 0 auto 24px;
			font-weight: 600;

			@media ${device.ipadLandscapemin} {
				width: 57%;
				margin: 0 auto;
				padding: 40px 0 50px 0;
				font-size: 4.2rem;
				line-height: 5rem;
			}
			sup {
				font-size: 60%;
			}
		}
	}
	.gene-therapy-trial {
		.o-container {
			padding: 0 !important;

			.timeline {
				@media ${device.laptopMedium} {
					background-size: 24%;
				}
				.fade-in-section {
					div.jobTitle {
						p {
							font-weight: 500;
							line-height: 2.4rem;
							font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
							@media ${device.ipadLandscapemin} {
								line-height: 32.4px;
							}
						}
					}
					div.jobdescription {
						p {
							@media ${device.laptopMedium} {
								line-height: 2.2rem;
							}
						}
					}
				}
			}
			.emerging-asterisk {
				p.indicated-for-treatment {
					font-size: 14px;
					line-height: 18px;
					font-family: ${font.gotham_book};
					text-align: center;
					@media ${device.ipadLandscapemin} {
						font-size: 18px;
						line-height: 22px;
					}
				}
			}
		}

	}
`;
export const HeaderWrapper = styled.div`
 .clinical-trials-banner {
		@media ${device.mobileMedium} {
			height: 100%;
		}
		@media ${device.tablet} and ${device.laptopMedium} {
			height: 470px;
		}
		@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
			height: 300px;
		}
		.internal-banner {
			height: 100%;
			.internal-banner__image {
				.desktop-image {
					@media ${device.tablet} and ${device.laptopMedium} {
						height: 470px;
					}
					@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
						height: 300px;
					}
					img {
						@media ${device.ipadLandscapemin} {
							object-position: center;
						}
					}
				}
				.mobile-image {
					img {
						@media ${device.mobileMedium} {
                        	height: 434px;
						}
					}
				}
			}
			.internal-banner__contant {
				.o-container {
					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						padding: 0 5rem;
					}
					h1 {
						font-family: "Gotham", Arial, Helvetica, sans-serif;
					}
				}
			}
		}
 	}
 .hcpEmergingScienceWrapper {
		.emerging-science-intro {
			.o-header {
				font-family: "Gotham", Arial, Helvetica, sans-serif;
					padding-top: 30px;
					padding-bottom: 20px;
			}
			.o-paragraph {
				padding-bottom: 20px;
				@media ${device.laptopMedium} {
					line-height: 2.2rem;
				}
			}
		}
			.hcp-footer-wrap {
				.footer-block .o-columns--two {
					&:last-child {
						.gtm-link-internal {
							p {
								@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
									padding-bottom: 62px;
								}
							}
						}
					}
				}
			}
 	}

 .hcpEmergingScience {
	.clinical-trials-banner {
		.internal-banner {
			.internal-banner__contant {
				.o-container {
					h1 {
						@media ${device.ipadLandscapemin} {
							padding-left: 0 !important;
						}
						@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
							max-width: 100% !important;
						}
					}
				}
			}
		}
	}
	.containercliniwrap {
		> div .o-container {
			@media ${device.ipadLandscapemin} and ${device.minideskend} {
				padding: 0px 5rem;
				max-width: 1208px !important;
				margin: 0 auto;
		}
			}
		}
		.hcp-footer-wrap {
			.footer-block {
				.footer-block--row {
					.footer-inner-block {
						.o-button {
							p {
								a {
									&:after {
										@media ${device.ipadLandscapemin} {
											width: 16px !important;
										}
									}
								}
							}
						}
						&:last-child {
							.o-button {
								p {
									a {
										&:after {
											@media ${device.ipadLandscapemin} {
												width: 16px !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
 }
`;

export const CalloutWithHeaderWrapper = styled.div`
	.o-text-center {
		text-align: center;

		@media ${device.laptopMedium} {
			font-size: 2rem;
			line-height: 2.6rem;
		}
		@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
			padding: 0 5rem;
		}
		@media ${device.desktopStart} {
			padding: 0 2rem;
		}
		.o-header--h2 {
			p	{
				font-size: 4.2rem;
				font-weight: normal;
				line-height: 5rem;
				color: #000000;
				font-family: 'Gotham';
				padding: 0 0 20px;
				margin: 0;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 2.5rem;
					line-height: 3rem;
				}
			}
		}

		.o-text--book {
			display: block;

			p {
				width: 80%;
				margin: auto;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				font-family: 'Gotham-Book';

				em {
					font-style: italic;
				}
			}

			&.pb-5 {
				&:last-child {
					padding-bottom: 0 !important;
				}
			}
		}
	}
`;
export const GeneTestEvolutionContainerWrapper = styled.div`
	margin: 10rem 0 5rem;
	position: relative;

	@media ${device.laptopMedium} {
		margin: 2rem 0 1rem;
		margin-bottom: 48px !important;
	}

	&:before {
    content: "";
    background: #00FFD9;
    display: block;
    width: 100%;
    height: 70%;
    position: absolute;
    top: 15%;

		@media ${device.laptopMedium} {
			top: 5%;
			height: 92%;
		}
	}

	.o-container {
		.c-family--story__inner {
			background-color: #f7f7f7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			position: relative;
			padding: 10rem 9rem;
			color: #000000;

			@media ${device.laptopMedium} {
				padding: 2rem 3rem;
				font-size: 2rem;
    		line-height: 2.6rem;
				margin-top: 48px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				width: 100%;
				height: 10px;
				position: absolute;
				top: 0;
				left: 0;
				height: 10px;
			}

			.o-header--h2 {
				font-family: "Gotham", Arial, Helvetica, sans-serif;
				text-align: center;
				margin: 20px 0 0 0;
				padding: 0;
				@media ${device.ipadLandscapemin} {
					margin: 0 0 40px 0;
				}
			}

			.genetic-evolution {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				position: relative;

				@media ${device.laptopMedium} {
					margin-top: 32px;
				}

				.evo-genetic-container {
					background-color: #000000;
    			margin-bottom: 50px;

					.o-header--h3 {
						color: #00FFD9;
						padding: 20px 10px 0;
						margin-top: 20px;
						font-family: "Gotham", Arial, Helvetica, sans-serif;
						@media ${device.ipadLandscapemin} {
							font-size: 3.4rem;
							line-height: 4.7rem;
							text-align: center;
							margin: 60px auto 10px;
							padding: 0 15rem;
						}
						sup {
							font-size: 60%;
						}
						p {
							font-size: 3.4rem;
    					line-height: 4.7rem;
							text-align: center;
							color: #00FFD9;
							margin-top: 60px;
							padding: 0 150px;
							font-weight: 600;
							font-family: 'Gotham';

							@media ${device.laptopMedium} {
								font-size: 2.5rem;
    						line-height: 3rem;
								margin: 20px 0 0 0;
								padding: 2rem 1rem 0;
								background-color: #000000;
    						width: 100%;
							}
						}
					}
					img {
						@media ${device.laptopPros} {
							padding: 1rem 1rem 0 !important;
						}
						@media ${device.laptopMedium} {
							width: 100%;
							padding: 1rem 0 0;
							margin-bottom: 32px;
						}

						.desktop-image {
							display: block;

							@media ${device.laptopPros} {
								display: none;
							}
						}

						.mobi-image {
							display: none;

							@media ${device.laptopPros} {
								display: block;
							}
						}
					}
					.mobile-graph-image {
						@media ${device.tablet} {
							padding:10px !important;
							display: none;
						}
					}

					.line-graph-copy {
						@media ${device.mobileMedium} {
							padding: 0 3rem !important;
						}
						@media ${device.laptopPros} {
							font-size: 16px;
							line-height: 20px;
						}
						margin: 24px 0;
						display: block;
						color: #fff;
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-size: 18px;
						line-height: 22px;
						text-align: center;
						color: #fff;

						@media ${device.ipadLandscapemin} {
							margin: 8px 0 40px;
						}
						@media ${device.smalldeskstrt} {
							padding: 0 62px 80px;
						}

						a {
							color: #00FFD9;
							cursor: pointer;
						}
					}
				}
				p.o-paragraph {
					width: 100%;
					@media ${device.ipadLandscapemin} {
						width: 80%;
					}
				}
				span.o-paragraph {
					color: #000;
					text-align: center;
					display: block;
					padding: 0 10px;
					@media ${device.ipadLandscapemin} {
						padding: 0;
					}
					span {
						color: #8000BE;
						text-align: center;
					}
				}
				p.o-paragraph {
					padding-bottom: 0;
				}
			}
		}
	}
`;
export const UnderwayTrailsContainerWrapper = styled.div`
	position: relative;
  margin: 5rem 0 15rem;

	@media ${device.laptopMedium} {
		margin: 0 0 101px;
	}

	&:before {
    content: "";
    display: block;
    background: #00FFD9;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 60px;

		@media ${device.laptopMedium} {
			height: 70%;
    	top: 115px;
		}
	}

	.o-container {
		.step-info-box {
			display: flex;
			flex-wrap: inherit !important;
			max-width: 1020px;
			margin: 120px auto 0;
			width: 100%;
			align-items: center;
			background-color: #300064;
			padding: 40px 100px;
			position: relative;

			@media ${device.laptopMedium} {
				margin-top: 30px;
    		display: block;
				margin: 50px auto 0;
				padding: 10px 10px 30px;
				justify-content: center;
			}

			img {
				width: 100px;
				position: relative;
				left: 5px;
				max-width: 100%;

				@media ${device.laptopMedium} {
					width: 40px;
					left: unset;
					margin: 24px auto;
					display: block;
				}
			}
			div.o-paragraph {
				@media ${device.tablet} and ${device.laptopMedium} {
					width: 90%;
				}
			}
			.o-paragraph {
				padding-bottom: 0;
				p {
					width: 90%;
    			margin: auto;
					text-align: left;
					line-height: 30px;
					color: #fff;
					font-size: 2.2rem;
					font-weight: 500;

					@media ${device.laptopMedium} {
						text-align: center;
						line-height: 2rem;
						padding: 0 30px;
						width: 100%;
						font-size: 1.8rem;
					}

					a {
						position: relative;
						text-decoration: underline;
						text-underline-offset: 3px;
						cursor: pointer;
						color: #fff;

						&:after {
							position: relative;
							top: 3px;
							margin-left: 10px;
						}

						@media ${device.laptopMedium} {
							padding-top: 10px;
							display:block;
						}

					}
				}
			}
		}
	}
`;
export const ContainerWrapper = styled.div`
	@media ${device.minimumDesk} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	@media ${device.largeS} {
		max-width: 1140px;
		margin: 0 auto;
		padding: 0 15px;
	}

	&.genes-wrapper {
		margin-bottom: 30px;

		@media ${device.laptopMedium} {
			margin-bottom: 0;
		}

		.o-container {
			.o-inner-wrapper-gens {
				padding: 0 50px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					padding: 0;
				}

				.o-genes-wrap {
					padding: 27px 90px;
					margin: 22px auto 0;
					box-shadow: 0 8px 24px 0 rgb(0 0 0 / 16%);
					display: flex;
					align-items: center;
					background-color: #300064;
					position: relative;
					width: 100%;

					@media ${device.laptopMedium} {
						margin: 0;
						padding: 29px 42px 25px 42px;
						display: block;
					}

					.info-box-2-icon {
						float: left;

						@media ${device.laptopMedium} {
							float: none;
						}

						img {
							left: 95px;
							width: 80px;

							@media ${device.laptopMedium} {
								margin-top: 0;
								width: 44px;
								right: 0;
								text-align: center;
								display: block;
								margin: auto;
							}
						}
					}

					.step-info-box {
						display: flex;
						padding: 0;
						flex-wrap: unset;
						width: auto;
						align-items: unset;

						@media ${device.laptopMedium} {
							display: block;
						}
						.o-container {
							padding: 0;
						}
						.o-inner {
							padding: 0;
						}
						.info-box {
							display: flex;
							@media ${device.laptopMedium} {
								display: block;
							}
							.infobox-middle-para {
								width: min-content;
								line-height: 20px;
								margin: auto 0;
								padding-left: 12px;
								@media ${device.laptopMedium} {
									width: 100%;
									margin: 10px 0 0;
									padding: 0;
									line-height: 2.4rem;
									display: flex;
									justify-content: center;
									align-items: center;
								}
								span {
									padding-top: 3px;
									font-size: 4.2rem;
									line-height: 40px;
									display: inline-block;
									@media ${device.laptopMedium} {
										font-size: 3.2rem;
										line-height: 3.5rem;
										padding: 0 6px;
									}
								}
							}
							.genes-info-box {
								text-align: left;
								margin: 0;
								padding: 0 0 0 32px;
								font-size: 2.2rem;
								line-height: 3.2rem;
								@media ${device.laptopMedium} {
									text-align: center;
									padding: 0;
									margin-top: 10px;
									font-size: 2rem;
									line-height: 2.4rem;
								}
							}
						}
						.over-genes-wrapper {
							color: #fff;
							font-weight: 500;
							text-align: center;
    						line-height: 20px;
							font-size: 2.2rem;
							margin: 7px 0 0;
							width: auto;

							@media ${device.laptopMedium} {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								line-height: 2.4rem;
								margin-top: 10px;
								font-size: 2rem;
							}

							span {
								line-height: 40px;
								padding-top: 3px;
								color: #00FFD9;
   								display: block;
								font-size: 4.2rem;

								@media ${device.laptopMedium} {
									font-size: 3.2rem;
									padding: 0 6px;
								}
							}
						}

						.over-genes-para {
							padding: 0 0 0 32px;
							font-size: 2.2rem;
							line-height: 3.2rem;
							font-family: "Gotham-Book";
							text-align: left;
    						color: #fff;
							font-weight: 500;
							margin: 0;

							@media ${device.laptopMedium} {
								font-size: 2rem;
    							line-height: 2.4rem;
								padding: 0;
								text-align: center;
    							margin-top: 10px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
	.emerging-science-gene-mods {
		.o-container {
			.o-text-book {
					padding-bottom: 0;
			}
		}
		.faq-block {
			.faq-question {
				.faq-answer {
					.faq-answer__faq-content {
						padding-bottom: 0;
					}
				}
			}
		}
	}
`;
export const TestorRetestPatientSectionWrapper = styled.div`
@media ${device.tablet} {
	width: 100%;
}
	.o-container {
		.info-box-2 {
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 40px 100px;
			position: relative;
			flex-wrap: wrap;
			width: 100%;

			span {
				color:#00FFD9;
			}

			@media ${device.laptopMedium} {
				padding: 20px 10px;
				margin: 0 0 18px 0;
				justify-content: center;
			}

			p {
				width: 80%;
				margin: auto;
				text-align: center;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #fff;

				@media ${device.laptopMedium} {
					width: 100%;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
			p.o-paragraph {
				padding-bottom: 0;
			}
		}
	}
`;
export const HcpFooterBlockWrapper = styled.div`
&.arrow-footer {
	.footer-block {
		padding: 88px 15px 0;
		display: flex;

		@media ${device.laptopMedium} {
			padding: 45px;
			display: block;
			margin-top: -1px;
		}

		.footer-block--row {
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;

			@media ${device.laptopMedium} {
				display: block;
			}

			.footer-inner-block {
				border-radius: 10px;
				background-color: #fff;
				padding: 25px 20px 120px;
				position: relative;
				margin: 0 15px;
				z-index: 992;
				box-shadow: 0 2px 2px rgb(17 17 17 / 16%);

				@media ${device.laptopMedium} {
					padding: 16px 20px 30px;
					width: 100%;
					margin: 30px auto 0;
				}

				&:before {
					width: 100%;
					height: 16px;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 9px 9px 0 0;
					content: "";
				}

				&:nth-child(2) {
					&:before {
						background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
					}
				}

				&:first-child {
					margin-left: 0;

					@media ${device.laptopMedium} {
						margin: 0 0 0 auto;
					}

					&:before {
						background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
					}
				}

				&:last-child {
					margin-right: 0;

					@media ${device.laptopMedium} {
						margin: 16px auto 0;
						padding: 16px 20px 20px;
					}
				}

				.footer-inner-block__small-icon {
					img {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;

						@media ${device.laptopMedium} {
							padding: 16px 0;
						}
					}
				}

				.o-common-title {
					p {
						strong {
							@media ${device.laptopMedium} {
								font-size: 2rem;
								line-height: 2.4rem;
							}
						}
					}
					p, h3 {
						min-height: unset !important;
						padding: 0 50px !important;
						font-weight: 500;
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;
						margin-bottom: 10px !important;

						@media ${device.laptopMedium} {
							padding: 0;
							font-size: 2rem;
    					line-height: 2.2rem;
							margin-bottom: 10px !important;
							padding: 0 !important;
						}
						strong {
							font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
						}
					}
					.footer-heading-style {
						margin-bottom: 10px;
					}
				}

				.o-text--book {
					p {
						width: 80%;
						margin: auto;
						padding: 20px 0;
						text-align: center;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							width: 100%;
							padding: 10px 0 0;
							font-size: 1.8rem;
    					line-height: 2.2rem;
						}
					}
				}

				.o-button {
					p {
						margin: 0;

						a {
							position: absolute;
							width: 90%;
							bottom: 25px;
							text-align: center;
							box-shadow: 0 3px 4px #d6d6d6;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 1.9rem;
							background: #8000BE;
							color: #fff;
							border: 1px solid #fff;
							font-family: 'Gotham';
							font-weight: 500;
							line-height: 3.2rem;
							text-decoration: none;
							z-index: 1;
							vertical-align: top;
							overflow: hidden;
							padding: 10px 25px;
							letter-spacing: 0;
							border-radius: 6px;
							transition: all 0.3s ease;
							cursor: pointer;

							@media ${device.laptopMedium} {
								position: relative;
								left: 0;
								bottom: 0;
								margin-top: 32px;
								width: 100%;
								display: block;
								padding: 10px;
								font-size: 18px;
								line-height: 2.2rem;
							}

							&:hover {
								background: #fff;
								color: #8000BE;
								border-color: #fff;
								font-weight: 700;
								text-decoration: none;
							}

							&:after {
								content: '';
								display: inline-block;
								width: 24px;
								height: 18px;
								position: relative;
								top: 1px !important;
								left: 7px;

								@media ${device.tablet} {
									width: 30px;
									width: 16px !important;
								}

								@media ${device.laptopPros} {
									display: block;
									margin: 10px auto 0;
									left: 0;
									top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
`;
