import React from 'react';
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby';
import { PageWrapper, HcpMobileBanner, HcpClinicalTrials } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import retinaConfig from '../../../utils/retinaConfigs';
import { ClinicalTrialsProvider } from '@retina-packages/retina-theme-bootstrap/packages/components/DNAComponent'
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap';
import {
  HeaderWrapper,
  CalloutWithHeaderContainer,
  CalloutWithHeaderWrapper,
  ContainerWrapper,
  GeneTestEvolutionContainerWrapper,
  HcpFooterBlockWrapper,
  TestorRetestPatientSectionWrapper,
  UnderwayTrailsContainerWrapper,
  NoCostGeneticTestingWrapper
} from './styles';

import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, footRefHTMLBuilder } from '../../../utils'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'
import { hcpIIR } from './constants';

const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsCR = props.pageContext
  const htmlStaticFilesCR = {
    nonSVGImages: pgContextsCR.nonSVGImages,
    svgMediaImages: pgContextsCR.svgMediaImages,
    allMediaDocument: pgContextsCR.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpIIR.hcpMainMenu, siteFooterMenu: hcpIIR.hcpFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix })
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const bannerTitle = ""
  const carouselTitle = hcpIIR.hcpCarousel
  const footerTitle = hcpIIR.hcpFooter
  const exitPopupTitle = hcpIIR.hcpExitPopup
  const hcpSwitcherTitle = hcpIIR.hcpHCPSwitcher
  const topNavTitle = hcpIIR.hcpTopNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const hcpClinicalTrialReference = footRefHTMLBuilder({ blocks, title: hcpIIR.hcpFootRef });
  const siteLogos: any = deriveSiteLogo({ blocks, title: hcpIIR.hcpSiteLogo })
  const sciAdvHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpSciAdvHTML });
  const evTestData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpEvolutionGTPara });
  const LatUnderTrialHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpLatUnderTrialHTML });
  const testOrRetestYourPatientsSectionData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpTestOrRetestPara });
  const navCalloutData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpNavCalloutPara });
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpBanner });
  const breakthroughData = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpBreakthroughData });
  const DnaData = blocks.filter((inf: any) => inf.relationships.field_reusable_paragraph.label === hcpIIR.hcpDnaData).map(
    (info: any) => info.relationships.field_reusable_paragraph.relationships.paragraphs
  ).at(0);
  const timelineHTML = DnaData?.field_para_text_gcso.value
  const dnaBackImage: string = DnaData?.relationships?.field_para_media_gcso[0]?.relationships?.field_media_image?.localFile?.publicURL
  const overThirtyHeader = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpOverThirtyHeader });
  return (
    <div className='hcppage'>
      <HeaderWrapper>
        <HcpClinicalTrials className="hcpEmergingScience">
          <HcpMobileBanner className="hcpmobilewrapper">
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              footref={hcpClinicalTrialReference}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              staticMediaFiles={htmlStaticFilesCR}
              siteLogos={siteLogos}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              backToTopContent={retinaConfig.backToTopContent}
              gtmBackToTopLabel={hcpIIR.backToTopGTM}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              toggleExpandAllUnderline={false}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl={retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={hcpIIR.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
            >
              <PageWrapper className='pagewrapper pagewrapper-clinical-trial hcpEmergingScienceWrapper'>
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesCR} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                <CalloutWithHeaderContainer className='main-section o-top-space emerging-science-intro'>
                  <CalloutWithHeaderWrapper className='o-container'>
                    <HTMLRenderer html={sciAdvHTML} data={htmlStaticFilesCR} tagName='div' className='o-text-center' />
                  </CalloutWithHeaderWrapper>
                  <GeneTestEvolutionContainerWrapper className='c-family--story evolution-genetic-test'>
                    <HTMLRenderer html={evTestData} data={htmlStaticFilesCR} tagName='div' className='o-container' />
                  </GeneTestEvolutionContainerWrapper>
                  {overThirtyHeader && (
                    <HTMLRenderer data={htmlStaticFilesCR} html={overThirtyHeader} />
                  )}
                  {timelineHTML && (
                    <ClinicalTrialsProvider html={timelineHTML} staticMedia={htmlStaticFilesCR} backgroundImage={dnaBackImage} />
                  )}
                  <UnderwayTrailsContainerWrapper className='o-primary-bg emerging-science-static'>
                    <HTMLRenderer html={LatUnderTrialHTML} data={htmlStaticFilesCR} className='o-container' tagName='div' />
                  </UnderwayTrailsContainerWrapper>
                  <ContainerWrapper className='containercliniwrap'>
                    <HTMLRenderer data={htmlStaticFilesCR} html={breakthroughData} />
                    <TestorRetestPatientSectionWrapper className='hcpretest-wrapper'>
                      <HTMLRenderer html={testOrRetestYourPatientsSectionData} data={htmlStaticFilesCR} className='o-container' tagName='div' />
                    </TestorRetestPatientSectionWrapper>
                    <HcpFooterBlockWrapper className='o-container hcp-footer-wrap arrow-footer'>
                      <div className='footer-block o-top-space emerging-science--footer'>
                        <HTMLRenderer html={navCalloutData} data={htmlStaticFilesCR} tagName='div' className='footer-block--row' simpleImage={true} />
                      </div>
                    </HcpFooterBlockWrapper>
                  </ContainerWrapper>
                </CalloutWithHeaderContainer>
              </PageWrapper>
            </Layout>
          </HcpMobileBanner>
        </HcpClinicalTrials>
      </HeaderWrapper>
    </div>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... HcpIIRPageQuery
      }
    }
  }
`